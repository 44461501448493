const ELIGIBLE_USERS_TEMP = [
  '0x33A875bD262C5cACAa1245ed8AC9734973da6108',
  '0x5E26F32454EF6219b48AE556ECc58279b033E506',
  '0xEA7D443EcB40E2189d674256DfF3CC32b35C1430',
  '0x3B7eB1e9CEd7d8c78E8A9b2D591dbc20FC42A1bC',
  '0x078E88E465f2a430399E319d57543A7A76E97668',
  '0x055A875cD844f04a2C2Ee1BB72999D7E7b40761d',
  '0xEDF7b675a2fE3c27efb263fb4c204A3f0fb17D46',
  '0x47547895218274e121E4219A3fD23235377A9903',
  '0x4902b20bB3B8e7776CBcDCB6e3397E7F6b4e449E',
  '0x0da46E79374f20235F62Cb91f6eDBa35245A3a84',
  '0x056590F16D5b314a132BbCFb1283fEc5D5C6E670',
  '0x5f350bF5feE8e254D6077f8661E9C7B83a30364e',
  '0x18637e9C1f3bBf5D4492D541CE67Dcf39f1609A2',
  '0x31fE8439F34ed04514288a6f0F19f26c647Cd6aD',
  '0xF4880975728EE25A96C701c225c9EF58Eebe5a5b',
  '0xFC902A0eb16161901a91368cabD9B5005555a162',
  '0x797BC498EdEE4357a16a1054dB0323CbD04C84c3',
  '0x0702F00bD689eE782D5c9a9DeDF1c7039F610f95',
  '0x7711A9a7Da504edF800Fe80f7E52A3aA33DAD07a',
  '0x14D92832265eeAFDEF9e526356FEfc90105966c3',
  '0xcb02c5065946D127d701E9b93802db4B8bE1C52F',
  '0x8eBed0FF2B0232B0AedbFe6e9c0f72AC72577869',
  '0xa8bF0cD4D76D92Cb345e81999029f3174A219305',
  '0xbde901046e67708c571CFfa4af722ba6993F2Cf2',
  '0x262fa5a227a463f5263FeCFE01Ff47B1d2F7bB86',
  '0xa2D20dB48709De22E407655cb6406fD7AC2717a9',
  '0x0D08E4b40064fbBebD3d70d56b4Da0315C92E4D3',
  '0xA161aF0E1AB3DBDA1f8085b489350fB0Df64a51E',
  '0xa7b5BEA843C129F565Ab1240b6CBB47f39361246',
  '0xeD2EE0A646B7fd9cD5795ca09ac5A6ffC1761A90',
  '0x5DE8Ac0d4e700821b55E848c4ac2d4467D8a38C0',
  '0x6583c05d4B6c77CF6928d80e824aC3C4CA79142A',
  '0xedb5A8730755e3A701621b922dAFC9908602BB94',
  '0xf1eE2Ac3b3478F5FF86e8be64222987139ad515b',
  '0xfEa04B2B2a3480658291199c7d14916151E3464d',
  '0xA98862a73b69946F8daD60BF6DB9AAbE86480377',
  '0xEfF7292DF948148394F85c2B659b2732b0948683',
  '0xE1510a9c968FD1b4B73ecE06dc3A6567e6155a38',
  '0xF7abF6DE7f26fb95c783263F5d02B571BBaCB2A4',
  '0xe12462eEBcD5436F3B098aC341811084C67c2682',
  '0x932Ae65a2ee0fAa85062C970278AC0F707C893F3',
  '0x746E621e9aa6AE538F98E734a65e540Dd98Dfc95',
  '0x353635D152d622C61a991C177C32E20A575164EC',
  '0xC06964266aef6366440d7DF544a70fa1a8960851',
  '0xF6B8A2D2E7ecDC13B265BA0BF3b56d173401eA44',
  '0xd35225De9af2Ca02D325118a57b0d5AD2D5fB16f',
  '0xE9Ee1adA09599090840a4e15D7278aF5b338443C',
  '0xD8f813Ec49bc39Bb52BA0817eC9BD6a8E653B760',
  '0xF287148C549A41E0f64ad816DB4204325eAC6F23',
  '0x9C5957440D9944890b06DB9654801F832DD7E5b2',
  '0xa91f68edb71bcB923707cB5A9731955D435Ec851',
  '0x45eBC736090424b36106c4A29714B10bB19A5C72',
  '0x884B2737F355E1Ae0C88609c10BF7BC2f761f815',
  '0xCCa11A8EdB05D64a654092e9551F9122D70EA80e',
  '0x19dfdc194Bb5CF599af78B1967dbb3783c590720',
  '0xc6C8AeCb1d9cA59A09d691cDa8539992655dbfB3',
  '0x60f931EE0Ad1b80242F26B624722a0734ac26db2',
  '0x010922A3A9e3DabcF6019581F4698a5c53202394',
  '0xdac959196d0c87b5F285CF259Ff7d615F7071235',
  '0xDB687064091B8D2df1A02273411a03B707e20003',
  '0x49C233D25768E462F0474390F653BAF40DD592b2',
  '0xE38F383DbA802c0C45A127F96354c077DdEe886f',
  '0xC77FA6C05B4e472fEee7c0f9B20E70C5BF33a99B',
  '0xA8EbE6DcDa66bB74a8DB994554d2Ad277cb54aDa',
  '0xcd43AaD533e663b726DAe4a08185E9db8eBC9f6F',
  '0x05eBf7B089a859af295B4F4D96673eDcB5ef6621',
  '0x2AFaB81b50410596Dcf6c290F1DC8344518E9970',
  '0xa9702237c06498a538aa60B1892f3e8500f97451',
  '0x392027fDc620d397cA27F0c1C3dCB592F27A4dc3',
  '0xd82Fd7fbFc8634C942d53993c9317Fe57CecA11F',
  '0x07f67cF4b8BFA3A1117CB802cCb9B54687c48047',
  '0xa89B35494dd78814052b487B7a71D1Fb280c0aFD',
  '0x5e25c391810eE5Bc705c87FB51c368612982D57a',
  '0xB4394f3DeaB6D7A9d27fB542EAfacc32c3C01076',
  '0x79b1A32EC97537486E75d99850BD56ECfA09D643',
  '0xE03fEa52f36c443fd7E9625aEA713a8461c180db',
  '0xa1C7bd2e48f7f3922e201705f3491C841135F483',
  '0x8f82808FD5cE99C4E34b56078ea55E244e34773c',
  '0xD1C76d57aeec45c17FB313A45a03b5c527B62174',
  '0xB5304935df1D3F810932666B708451F55F143913',
  '0xa195D95a0f1A6A0c7414159B2b28E23E9f550Fb4',
  '0x171E86C5848cfDDab768E9C2f1F591435d5315c9',
  '0xc97239fda221124DCDac5612B45C208163c1D4f3',
  '0xf8e6f23f18a04Bfd481cE71ac894488568285f1b',
  '0x9C3E169E4885E3F720Bc34337ceC3b6b11AAE254',
  '0x7E8ecCb534108cD2e4975267069e9Fc493E0304B',
  '0xB290Fd44A628A21FCCBf6347668D80dE4177dA42',
  '0x4034E70f8D84e3ac931f4c6771DA1DCEA1DDCf53',
  '0x466AcfF01de5D7a9C8a9cE9744618bb717E25A3B',
  '0xCa9ba74eE20917211ef646AC51ACcc287F27538b',
  '0x3E8a5eF9c182bff3F3c171FffaCc14E110281B30',
  '0x074AD9Ec101d680d480BF70841124D32d8b2664A',
  '0x09b412eBBf9B2F40fB3419ff96b84B9A8EC3Df89',
  '0xDff6fF5d17097fC8DC038606cC68581fc0634a4C',
  '0x6be9a7100df73E81855c34497bfE17015291D5a2',
  '0x181cb9866F4d0489163c588DaDc084768e3B7012',
  '0x22f6EDb55790B0Fe841C5BA9aa30792ebcB55863',
  '0x38Dd4D1Cf82B0943d1ED300A792900D252e21921',
  '0xDFE2b668c6D4a3FA785f86cB2c64E54E30c16cb0',
  '0x90C941b9eF1B662165b8c0Ff3d08Ce4B0E697fAB',
];

export const ELIGIBLE_USERS = ELIGIBLE_USERS_TEMP.map(userAddress => userAddress.toLowerCase());
